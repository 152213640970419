define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en", {
    "acma": {
      "header": "",
      "image_alt": "Australian Communications and Media Authority",
      "image_title": "Australian Communications and Media Authority",
      "subtitle": "",
      "welcome": "Welcome to the ACMA Auction System"
    },
    "actions": {
      "apply": "apply",
      "cancel": "cancel",
      "save": "save"
    },
    "boem": {
      "header": "",
      "image_alt": "Bureau of Ocean Energy Management",
      "image_title": "Bureau of Ocean Energy Management",
      "subtitle": "",
      "welcome": "Welcome to the BOEM Auction System"
    },
    "config": {
      "base_flash_messages": {
        "api_error_fallback": "Your request could not be processed. Please refresh your page and try again.",
        "payload_too_large": "E002: Your upload exceeded the maximum file size limit."
      },
      "external_request_max_mb": "20"
    },
    "date": {
      "formats": {
        "human": "ddd, MMM D",
        "long": "MM/DD/YYYY",
        "short": "MMM D"
      }
    },
    "datetime": {
      "formats": {
        "human": "ddd, MMM D, h:mm a",
        "long": "MM/DD/YYYY hh:mm:ss a",
        "short": "MMM D, h:mm a"
      }
    },
    "demo": {
      "header": "",
      "image_alt": "Demo Infrastructure Authority",
      "image_title": "Demo Infrastructure Authority",
      "subtitle": "",
      "welcome": "Welcome to the Demo Auction System"
    },
    "empty_states": {
      "no_logs": "No Logs"
    },
    "fcc": {
      "header": "Federal Communications Commission",
      "image_alt": "Federal Communications Commission",
      "image_title": "Federal Communications Commission",
      "subtitle": "Auction System",
      "welcome": "Welcome to the FCC Auction System"
    },
    "filtering": {
      "apply_filters": "filter",
      "reset_filters": "reset",
      "summary": {
        "long": {
          "all": "Showing {lower_bound} to {upper_bound} of {total} {filter_object_name}",
          "filtered": "Showing {lower_bound} to {upper_bound} of {total} {filter_object_name}",
          "single": "Showing {filter_object_name} {position} of {total}"
        },
        "short": {
          "all": "Showing {count} of {total} {filter_object_name}",
          "filtered": "Showing {count} of {total} {filter_object_name}"
        },
        "simple": {
          "all": "Showing {count} {filter_object_name}",
          "filtered": "Showing {count} {filter_object_name}"
        }
      }
    },
    "messages": {
      "discard_changes_dialog": {
        "body": "Do you want to discard your changes?",
        "header": "Discard changes"
      }
    },
    "singular_subjects": {
      "role": "role",
      "user": "user"
    },
    "symbols": {
      "dot": "•",
      "surround_with_parens": "({string})"
    },
    "theme-version-for-testing": {
      "header": "",
      "image_alt": "Testing Infrastructure Authority",
      "image_title": "Tessting Infrastructure Authority",
      "subtitle": "",
      "welcome": "Welcome to the Testing Auction System"
    },
    "time": {
      "am": "am",
      "formats": {
        "human": "h:mm a",
        "long": "hh:mm:ss a",
        "military": "H:mm"
      },
      "pm": "pm",
      "timezone": "America/New_York",
      "timezone_abbr": "ET"
    }
  }]];
  _exports.default = _default;
});